<template>
  <div id="UserList">
    <title-nav :title="'회원현황'" :nav="'회원관리 / 회원현황'" :sub-title="userSummary" />
    <b-container fluid>
      <div class="card">
        <div class="card-header">
          <search :isDate="true" :isStore="true" @search="item => setCondition(item)"></search>
        </div>
        <div class="card-body p-0">
          <b-table
            striped
            hover
            bordered
            class="table-sm text-sm-center table-head-fixed"
            :borderless="borderless"
            :dark="dark"
            :fixed="fixed"
            :no-border-collapse="noCollapse"
            :items="items"
            :fields="fields"
            :head-variant="headVariant"
            :busy="isBusy"
            show-empty
          >
            <!-- 회원번호 -->
            <template v-slot:cell(user_sn)="data">
              <router-link
                :to="{ name: 'detail', params: { usersn: data.value } }"
                variant="link"
                v-if="data.item.user_stat_cd === 'TEMP' || data.item.user_stat_cd === 'NORMAL'"
                >{{ data.value }}</router-link
              >
              <p v-else>{{ data.value }}</p>
            </template>

            <!-- 가입일수 
            <template v-slot:cell(reg_dt)="data">
              <span>{{ data.value }}</span>
            </template>-->

            <!-- 추천인ID 
            <template v-slot:cell(user_stat_cd)="data">
              {{ getCodeName(data.value) }}
            </template>-->

            <!-- 최근접속기록 -->
            <template v-slot:cell(last_login_dt)="data">
              <span>{{ data.value }}</span>
            </template>

            <!-- 추천코드 & 가입자 수 
            <template v-slot:cell(recom_cnt)="data">
              <b-button
                size="sm"
                variant="outline-dark"
                @click="openRecommList(data.item.user_sn)"
                class="oneline"
                v-if="data.item.my_code"
                >{{ data.item.my_code }}
                <b-badge variant="primary">{{ data.value }}</b-badge></b-button
              >
            </template>-->

            <!-- 추천 코드 -->
            <template v-slot:cell(recom_sn)="data">
              <router-link
                v-if="data.value"
                :to="{ name: 'detail', params: { usersn: data.value } }"
                variant="link"
                >{{ data.value }}</router-link
              >
              <p v-else>{{ data.item.recom_event ? data.item.recom_event : '' }}</p>
            </template>

            <!-- 가입매체 -->
            <template v-slot:cell(provider_cd)="data">
              <template v-if="data.value"
                ><template v-if="data.item.from_cd"
                  >{{ data.value }}<br />{{ data.item.from_cd }}</template
                ><template v-else>{{ data.value }}</template></template
              >
              <template v-else>{{ data.item.store_cd }}</template>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:empty>
              <h4>데이터가 없습니다.</h4>
            </template>
          </b-table>
        </div>
        <div class="card-footer clearfix">
          <b-pagination
            v-model="page"
            :total-rows="records"
            :per-page="rowPerPage"
            @change="setPage"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </b-container>
    <template>
      <b-modal title="추천인 리스트" ref="modal_recom_list" :hide-footer="true">
        <b-table
          striped
          hover
          bordered
          class="table-sm text-sm-center"
          :borderless="borderless"
          :dark="dark"
          :fixed="fixed"
          :no-border-collapse="noCollapse"
          :fields="recomm_fields"
          :head-variant="headVariant"
          :busy="isBusy"
          :items="recomm_user_list"
          show-empty
          responsivee
          stickyHeader
        >
        </b-table>
      </b-modal>
    </template>
  </div>
</template>

<script>
import Search from '../util/Search'
import { getUserList, getUserCode, getRecommUserList, getUserSummary } from '@/api/user'

export default {
  name: 'UserList',
  components: {
    Search
  },
  data() {
    return {
      page: 1,
      records: 0,
      rowPerPage: 20,
      modalPage: 1,
      isBusy: false,
      nickname: '',
      email: '',
      phoneno: '',
      invite_email: '',

      selectedNum: '',
      recomm_fields: [
        {
          key: 'no',
          label: 'No.',
          colType: 'span'
        },
        {
          key: 'email',
          label: 'Email',
          colType: 'span'
        },
        {
          key: 'user_name',
          label: '닉네임',
          colType: 'span'
        }
      ],
      fields: [
        {
          key: 'user_sn',
          label: '회원번호',
          colType: 'span',
          // sortable: true,
          tdClass: 'text-left',
          thStyle: { minWidth: '90px' }
        },
        {
          key: 'email',
          label: '이메일',
          colType: 'span',
          tdClass: 'text-left'
        },
        {
          key: 'provider_cd',
          label: '가입매체',
          colType: 'span'
        },
        {
          key: 'user_name',
          label: '닉네임',
          colType: 'span'
        },
        {
          key: 'hpno',
          label: '휴대전화번호',
          colType: 'span'
        },
        {
          key: 'reg_dt',
          label: '가입일',
          colType: 'span'
        },
        {
          key: 'upd_dt',
          label: '수정일(탈퇴일)',
          colType: 'span'
        },
        {
          key: 'user_stat_cd',
          label: '상태',
          colType: 'span',
          thStyle: { minWidth: '45px' }
        },

        {
          key: 'last_login_dt',
          label: '최근접속기록',
          colType: 'span'
        },
        {
          key: 'recom_sn',
          label: '추천인',
          colType: 'span',
          tdClass: 'text-left'
        } /*,
        {
          key: 'recom_cnt',
          label: 'My 코드',
          colType: 'span',
          tdClass: 'text-left'
        }*/
      ],
      items: [],
      recomm_user_list: [],
      striped: true,
      bordered: false,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      tableVariant: '',
      noCollapse: false,
      headVariant: 'dark',
      code: '',
      search: { key: '', value: '', start: '', end: '' },
      userSummary: '<div></div>'
    }
  },
  methods: {
    getCodeName(code) {
      const name = this.code && this.code.find(value => value.cd_value === code).cd_name_kr
      // console.log(`name: ${name}`)
      return name
    },
    async init() {
      this.isBusy = true

      this.getList()
      this.getSummary()

      this.isBusy = false
    },
    async getSummary() {
      const resp2 = await getUserSummary(
        this.page,
        this.rowPerPage,
        this.search.key,
        this.search.value,
        this.search.start,
        this.search.end,
        this.search.store
      )
      if (resp2.code === '0000') {
        this.userSummary = `<div style="padding:0.5rem;">
                총 가입자 <span class="badge badge-info badgeNum">${resp2.data.total}</span>
                임시회원수 <span class="badge badge-info badgeNum">${resp2.data.temp}</span>
                정상회원수 <span class="badge badge-danger badgeNum">${resp2.data.normal}</span>
                탈퇴회원 수 <span class="badge badge-info badgeNum">${resp2.data.withdraw}</span>
        </div>`
      }
    },
    async getList() {
      this.isBusy = true
      const response = await getUserList(
        this.page,
        this.rowPerPage,
        this.search.key,
        this.search.value,
        this.search.start,
        this.search.end,
        this.search.store,
        this.search.searchDate
      )
      if (response.code === '0000') {
        this.items = response.data.list
        this.records = response.data.totCnt
      } else {
        alert(response.message)
      }
      this.isBusy = false
    },
    setPage(page) {
      this.page = page
      this.getList()
    },
    setCondition(search) {
      this.usersn = this.email = ''
      switch (search.key) {
        case 'usersn':
          this.usersn = search.value
          break
        case 'email':
          this.email = search.value
          break
        case 'nickname':
          this.email = search.value
          break
        case 'referID':
          this.email = search.value
          break
      }
      this.page = 1
      this.search = search
      this.init()
    },
    sendInfo(num) {
      this.selectedNum = num
      // this.getDetail(this.selectedNum)
    },
    async openRecommList(user_sn) {
      const response = await getRecommUserList(user_sn)
      if (response.code === '0000') {
        this.recomm_user_list = response.data
        this.$refs['modal_recom_list'].show()
      } else {
        alert(response.message)
      }
    },
    async setCodeList() {
      const response = await getUserCode()
      if (response.code === '0000') {
        this.code = response.data
      } else {
        alert(response.message)
      }
    }
  },
  mounted() {
    this.setCodeList()
    this.init()
  }
}
</script>
